@import '../../../../lib/assets/styles/var';

.meeting-reminder {
  margin-top: 36px;
  height: 160px;

  .wrapper {
    height: 100%;
    width: 100% !important;
    background-color: $primary_dark;
    border-radius: 5px;
    overflow: hidden;

    .africa-jumbotron {
      text-align: right;
      font-family: $header_text;
      font-style: italic;
      font-size: 55px;
      line-height: 86px;
      letter-spacing: 0px;
      color: $primary;
      opacity: 0.91;
      z-index: 1;

      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      background: inherit;
      background-attachment: fixed;

      -webkit-text-stroke: 2px $primary;
      -webkit-text-fill-color: transparent;
      -webkit-animation: fill 0.5s;
    }

    .africa-wrapper {
      margin-top: 1.5rem;
    }

    .africa-meeting-header {
      color: $white;
      font-size: 25px;
      font-family: $header_text;
      line-height: 33px;
      opacity: 1;
      margin-bottom: 0;
      z-index: 3;
    }

    .meeting-time {
      color: $black;
      font-size: 18px;
      font-family: $body_text;
      line-height: 27px;
      opacity: 1;
      z-index: 3;
    }
  }
}
