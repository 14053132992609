.ant-modal-close-x {
  display: none;
}

// .create_account_modal_icon {
//   border: 1px solid #dbdbdb;
//   border-radius: 50%;
//   width: 70px;
//   height: 70px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin: 0 auto;
// }

.create_account_modal_btn,
.create_account_modal_btn:hover {
  border-radius: 5px;
  // width: 500px;
  border: 0;
  font: 'normal normal bold 16px/18px TT Norms Pro';
  opacity: 1;
  background: #309191 !important;
  height: 50px;
  color: #fff !important;
}

.ant-modal-close-x {
  display: none !important;
}

.ant-btn {
  display: inline-block !important;
}
