@import '../../../assets/styles/var';
@import '../../../assets/styles/mixins';

.mobileSidebar {
  background-color: $primary;
  width: 100%;
  position: fixed;
  top: 80px;
  padding: 20px 0;
  transition: 1000ms ease;
  height: calc(100% - 80px);
  z-index: 100;
  overflow-y: auto;
  overflow-x: hidden;
  visibility: visible !important;
  z-index: 200;

  //   @include small-screens {
  //     width: 0;
  //     height: 0;
  //   }

  @media screen and (max-width: 576px) {
    width: auto;
  }

  .close_icon {
    float: right;
    font-size: 2rem;
    color: $white;
    padding-right: 20px;
    cursor: pointer;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $primary;
  }

  &::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #333;
  }

  .link-text {
    visibility: visible !important;
  }

  .active .nav-body {
    background-color: $primary_light;

    svg {
      margin-left: 0rem !important;
    }
  }

  .dropdown-icon {
    display: block;
    margin-left: 3rem;
  }

  .modules-menu {
    display: block;
    margin-top: -1rem;
    padding-left: 3rem;

    a {
      margin-bottom: -2rem;
      font-size: 16px;
    }
  }

  .sidebar-nav {
    list-style: none;
    padding: 0;
    margin: 0 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    .nav-item {
      width: 100%;

      &:last-child {
        margin-top: auto;

        .link-text {
          visibility: hidden;
          margin-left: 1rem;
        }
      }

      a {
        display: flex;
        align-items: center;
        height: 5rem;
        color: $white;
        font-family: $header_text;
        text-decoration: none;
        transition: 50ms;
      }

      .nav-body {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        display: flex;
        align-items: center;
        background-color: $primary_dark;
        transition: 600ms ease;

        .link-text {
          visibility: hidden;
          margin-left: 2rem;
        }

        svg {
          fill: $white;
          margin-left: 0.8rem;
        }

        .link-icon {
          height: 50px;
          width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .active .nav-body {
        width: 100%;
        background-color: $primary_light;
        color: $primary_dark;
        padding-left: 0px;

        svg {
          fill: $primary_dark;
        }

        .link-text {
          margin-left: 1rem;
        }
      }
    }
  }
}
