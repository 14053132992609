@import '../../assets/styles/var';

.greenpole-button {
  height: 30px;
  border-radius: 3px;
  padding: 0rem 3rem;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;

  &:disabled {
    background-color: transparent;
    border: 1px solid #ddd;
    color: #666;
    cursor: not-allowed;

    &:hover {
      background-color: transparent;
      border: 1px solid #ddd;
    }
  }
}

.btn-primary {
  background-color: $primary;
  border: 1px solid $primary;
  color: $white;

  &:hover {
    background-color: $primary_dark;
    border: 1px solid $primary_dark;
  }
}

.btn-secondary {
  background-color: $white;
  border: 1px solid $primary;
  color: $primary;

  &:hover {
    background-color: $primary_dark;
    color: $white;
  }
}
