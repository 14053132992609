@import '../../assets/styles/var';

.steps {
  background-color: $white;
  border-bottom: 5px solid #f4f7fa;
  padding: 8px !important;
}

.steps .container {
  padding: 0;
}

.rc-steps {
  background-color: $white;
  padding: 20px 0;
}

.rc-steps-item {
  border-right: 1px solid $grey;
  display: flex;
  flex: none;
  justify-content: center;
  margin-right: 30px !important;
  padding-right: 20px;

  &:first-child {
    justify-content: flex-start;
  }

  &:last-child {
    border-right: none;
  }
}

.rc-steps-item-container {
  display: inline-flex;
  align-items: center;
}

.rc-steps-item-icon {
  position: relative;

  &::before {
    content: '';
    width: 10px;
    height: 7px;
    position: absolute;
    top: 3px;
    left: 3px;
    border: 3px solid $white;
    border-top: none;
    border-right: none;
    background: transparent;
    transform: rotate(-45deg);
  }

  & .rc-steps-icon {
    display: none;
  }
}

.rc-steps-item-title {
  font-size: 18px;
  text-transform: capitalize;

  &:after {
    display: none;
  }
}

.rc-steps-item-finish {
  & .rc-steps-item-icon {
    border-color: $primary;
    background-color: #299f9e;
  }
}

.rc-steps-item-active {
  & .rc-steps-item-icon {
    border-color: #299f9e;
    background-color: #299f9e;
  }

  & .rc-steps-item-title {
    color: $primary;
    font-family: $header_text;
  }
}

.rc-steps-item-wait {
  & .rc-steps-item-icon {
    border-color: #ccc8c8;
    background-color: #ccc8c8;
  }
}
