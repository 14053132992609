@import '../../../assets/styles/var';

.right-sidebar {
  background-color: $white;
  width: 100px;
  position: fixed;
  top: 80px;
  right: 0;
  padding: 20px 0;
  transition: 250ms ease;
  height: calc(100% - 80px);
  z-index: 99;
  box-shadow: 0px 18px 48px #3e649208;
  border-left: 1px solid #3e649208;

  .sidebar-nav {
    list-style: none;
    padding: 0;
    margin: 0 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    .nav-item {
      width: 100%;

      .nav-body {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        display: flex;
        align-items: center;
        background-color: $background_color;
        transition: 600ms ease;
        padding-right: 2rem;
        margin-top: 2rem;

        .link-text {
          visibility: hidden;
          margin-left: 2rem;
        }

        svg {
          fill: $grey;
          margin-left: 0.8rem;
        }

        .link-icon {
          height: 50px;
          width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.task-header-link {
  text-align: right;
  text-decoration: underline;
  font: normal normal medium 16px/30px $body_text;
  letter-spacing: 0.16px;
  color: $primary_dark;
  opacity: 1;
  width: 90%;
  display: block;
}

.task-drawer-column {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.task-drawer {
  .form-message {
    text-align: left;
    font: normal normal medium 16px/4px $body_text;
    letter-spacing: 0.08px;
    color: $primary;
    opacity: 1;
  }

  .mag-form-extra-text {
    text-align: left;
    font-size: 16px;
    padding-top: 3em;
  }

  .mag-form-input,
  .mag-form-select.ant-select-single,
  .mag-form-select.ant-select-multiple .ant-select-selector {
    // min-height: 50px;
    width: 100% !important;
    border-radius: 5px;
    font-size: 18px;
    padding: 5px 10px;
    padding-right: 10px;
    text-transform: capitalize;
    border: 2px solid $form_input_color;
    background-color: $form_input_color !important;

    &::placeholder {
      font-size: 18px;
      padding: 18px 18px;
      color: $grey;
    }
    &:focus,
    &:hover {
      border-color: $primary_dark;
    }
  }

  .mag-form-select.ant-select-single {
    padding: 0;

    .ant-select-selector {
      border: none;
      background-color: $form_input_color;
      border-radius: 5px;
      font-size: 18px;
      // height: 50px;
      padding-top: 4px;
      padding-right: 4px;
      text-transform: capitalize;
    }
  }

  // this does not work because there is a
  // global select on the table
  .mag-form-input .ant-picker-dropdown {
    table.ant-picker-content {
      tr {
        &:nth-child(even) {
          background-color: white !important;
        }
      }

      th {
        background-color: white;
      }
    }
  }

  .mag-submit-btn {
    height: 60px;
    border-radius: 5px;
    padding: 0rem 3rem;
    font-weight: bold;
    font-size: 20px;
    background-color: $primary;
    border: 1px solid $primary;
    color: $white;

    &:focus,
    &:hover {
      background-color: $primary_dark;
      border: 1px solid $primary_dark;
      color: $white;
    }

    &.mt-4 {
      margin-top: 4em;
    }
  }
}

.ant-input-affix-wrapper > input.ant-input {
  background: #f7f7f7;
  height: 40px;
}

// Event calendar
// .custom-calendar {
//   box-shadow: none;
//   font-size: 13px;

//   .Calendar__weekDays {
//     color: $user_role_color;
//     font-weight: bold;
//   }
// }

.custom-today-day {
  color: $primary !important;
  border: 1px solid $primary !important;
}

.custom-today-day::after {
  visibility: hidden;
}

.title-warning {
  color: $primary;
  font-size: 18px;
  padding-left: 1.5em;
  margin-bottom: 1em;
}

// overiding Antd tags
.drawer-tags {
  background-color: $primary_dark !important;
  border: 1px solid $primary_dark !important;
  color: $white !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  height: 40px !important;
  line-height: 27px !important;
  padding-top: 4px !important;
  margin-right: 8px !important;
  margin-bottom: 2px !important;
}
