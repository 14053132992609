@import '../../../assets/styles/mixins';

.main {
  margin-left: 100px;
  padding-top: 100px;
  // position: fixed;
  // width: 95%;
  // margin-right: 100px;

  @media only screen and (max-width: 769px) {
    margin-left: 0;
  }

  @media only screen and (max-width: 576px) {
    width: 1000px;
  }

  @include small-screens {
    margin-left: 0;
  }

  &.primary {
    background: #f2f6fa;
    min-height: 70vh;
  }

  &.white {
    background: #ffffff;
  }
}
