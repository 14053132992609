.LegendDot {
  height: 8px;
  width: 8px;
  background-color: #687da0;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.4em;
}

.LegendItemContainer {
  margin-bottom: 0.3em;
}

.LegendItem {
  font-size: 0.75em;
  color: #666666;
}

.Priority {
  color: #687da0;
  font-size: 0.75em;
  padding-left: 1em;
}

.PriorityRow {
  margin: 0.5em;
}
