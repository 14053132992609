@import '../../assets/styles/var';

.summary-card {
  border-radius: 5px;
  min-height: 100%;
  padding-right: 2.5rem !important;

  .ant-row {
    padding-top: 30px;
  }

  .icon-container {
    border-right: 1px solid #d6d6d6;
    padding-right: 10px;
    width: 100px;
    height: 50px;
  }

  .icon-wrapper {
    display: inline-flex;
    height: 44px;
    width: 44px;
    padding: 0 10px;
    position: relative;

    background-color: $primary_light;
    border: 1px solid $primary;
    border-radius: 50%;

    .icon-img {
      position: absolute;
      display: block;
      height: 2em;
      width: 2em;
      top: 0.5em;
      left: 0.5em;
    }
  }

  .stats-text-group {
    width: 100%;
    padding-left: 8px;
  }

  .stats-units {
    color: $header_text_color;
    font-family: $header_text;
    font-size: 25px;
    line-height: 20px;
    word-break: break-all;
  }

  .stats-text {
    color: $body_text_color;
    font-family: $body_text;
    font-size: 1rem;
    line-height: 20px;
    text-transform: capitalize;
    word-wrap: break-word;
    width: 100%;
  }
}
