.success-modal {
  .ant-modal-header {
    background-color: #299f9e !important;
  }

  .ant-modal-title {
    text-align: center;
    color: #ffffff;
  }

  .ant-modal-close-x {
    color: #ffffff;
    display: block !important;
  }

  .ant-modal-body {
    padding: 0px;
  }

  .content {
    display: flex;
    justify-content: center;
  }
}

.reminderpopup-modal {
  .popup-save-button {
    background-color: #299f9e;
    color: #ffffff;
    border-radius: 3px;
    width: 35%;
    border: none;
    font-weight: 700;
    margin-bottom: 1em;
    margin-right: 1em;
  }
  .ant-modal-header {
    background-color: #299f9e !important;
  }

  .ant-modal-title {
    text-align: center;
    color: #ffffff;
  }

  .ant-modal-close-x {
    color: #ffffff;
    display: block !important;
  }

  .ant-modal-body {
    padding: 0px;
  }

  .content {
    display: flex;
    justify-content: center;
  }

  .time-container {
    display: flex;
    padding: 1em;
    .time {
      p {
        line-height: 1.4em;
        padding-left: 0.5em;
        // padding-top: 0.1em;
        color: #1b3c70;
        font-size: 1.2em;
        font-weight: 700;
      }

      small {
        color: #a7b3c7 !important;
        font-weight: normal;
      }
    }

    .timeIcon {
      .icon {
        color: #a7b3c7;
      }
    }
  }

  .notes {
    border-top: 3px solid #f2f3f6;
  }
}
