@import '../../assets/styles/var';

.form-field label {
  font-size: 0.8rem !important;
}
.file-input {
  color: $grey;
  border: 1px dashed #b7b7b7;
  background-color: $field_bg;
  border-radius: $primary_radius;
  display: grid;
  place-items: center;
  transition: all 0.3s;

  & input[type='file'] {
    display: none !important;
  }

  & .inst {
    display: flex;

    & img {
      margin-right: 4px;
    }

    & .browse {
      color: $primary;
      text-decoration: underline;
      margin-left: 4px;
      cursor: pointer;
    }
  }
}

.highlight {
  border-color: $primary;
  background-color: lighten($primary, 55%);
}
