@import '../../assets/styles/var';

.greenpole-tabs {
  background-color: $background_color;
  -webkit-box-shadow: -1px 1px 5px -1px rgba(102, 102, 102, 0.25);
  -moz-box-shadow: -1px 1px 5px -1px rgba(102, 102, 102, 0.25);
  box-shadow: -1px 1px 5px -1px rgba(102, 102, 102, 0.25);
  display: flex;
  flex-direction: column;

  .tab-item {
    display: flex;
    align-items: center;
    color: $grey;
    padding: 0;
    font-size: 14px;
    border: 0;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    margin: 0;
  }

  .active-tab {
    background-color: $white;
    color: $primary_dark;
    font-weight: bold;
  }
}

.tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 4rem;
}

.tab-content {
  padding-top: 2rem;
  background-color: $white;
  border-top: 2px solid $background_color;
}

.greenpole-category-tabs {
  width: 100%;
  padding-left: 0px;

  .tab-headings {
    display: flex;
    padding-left: 0rem;

    .tab-item {
      padding: 0rem 1rem;
      font-size: 14px;
      height: 3rem;
      display: flex;
      align-items: center;
      cursor: pointer;

      &::after {
        border-right: 1px solid #cccccc;
        height: 1.2rem;
        content: '';
        display: block;
        position: relative;
        margin-right: -1rem;
        padding-left: 1rem;
      }

      &.active {
        background-color: $white;
        border-top-left-radius: 3px;
        border-top-left-radius: 3px;

        &::after {
          border-right: 0px;
        }
      }

      span {
        margin-left: 0.5rem;
        width: 39px;
        height: 21px;
        background: #e5e5e5;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:last-child {
        &::after {
          border-right: 0px;
        }
      }
    }
  }

  .tab-body {
    width: 100%;
    background-color: $white;
    margin-top: -1.1rem;
  }
}
