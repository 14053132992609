@import '../../assets/styles/var';

.tabs-with-content {
  padding-left: 4.5em;
  background: $faded_grey;
  color: $deep_grey;
  font-size: 1rem;
  border: 0.5px solid $faded_grey;
  margin-bottom: 1em;

  .tab-item {
    padding: 1.2rem 1em;
    color: $grey;
    font-size: 18px;
    border: 0;
    outline: none;
    background-color: transparent;
    // flex: 1 1 0;
    cursor: pointer;
  }

  .active-tab {
    background: #ffffff;
    font-family: $header_text;
    color: $accent_green;
  }
}
