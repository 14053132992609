@mixin small-screens() {
  @media only screen and (max-width: 480px) {
    @content;
  }
}

@mixin medium-screens() {
  @media only screen and (max-width: 680px) {
    @content;
  }
}

@mixin large-screens() {
  @media only screen and (max-width: 960px) {
    @content;
  }
}
