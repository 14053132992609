@import '../../../../lib/assets/styles/var';

.notification-card {
  border: 1px solid lighten($grey, 40%);
  padding: 5px 12px;
  border-radius: 5px;
  margin-bottom: 1em;
  cursor: pointer;

  .subject {
    font-family: $header_text;
    font-size: 12px;
    line-height: 11px;
    display: inline-block;
  }

  .second-column {
    text-align: end;
  }

  .time-container {
    text-align: end;

    .time {
      font-size: 0.5em;
      color: $grey;
      display: inline-block;
      line-height: 19px;
      margin-top: -3px;
    }
  }

  .priority-container {
    text-align: end;

    .successful-status {
      border: 1px solid #299f9e;
      padding: 0.3em 1em;
      border-radius: 10px;
      color: #2c8181;
      background-color: #d6f6f6;
    }

    .pending-status {
      border: 1px solid #e8ac73;
      padding: 0.3em 1em;
      border-radius: 10px;
      font-weight: bolder;
      color: #e8ac73;
      background-color: #fdf6ed;
    }

    .failed-status {
      border: 1px solid #dc473f;
      padding: 0.3em 1em;
      border-radius: 10px;
      color: #dc473f;
      background-color: #fff6ed;
    }
  }

  .from {
    color: $grey;
    width: 60%;
    display: inline-block;
    margin-bottom: 0;
  }
}
