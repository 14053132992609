// Import Fonts
@font-face {
  font-family: 'TTNormsProRegular';
  src: url('../fonts/tt-norms-pro/TTNormsProRegular.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'TTNormsProBold';
  font-weight: bold;
  src: url('../fonts/tt-norms-pro/TTNormsProBold.otf') format('opentype');
  font-display: swap;
}

// Colors
$primary: #299f9e;
$primary_light: #d7f7f7;
$primary_dark: #309191;
$black: #000;
$grey: #666;
$white: #fff;
$header_text_color: #000;
$body_text_color: #666;
$background_color: #f4f7fa;
$field_bg: #f7f7f7;
$accent_red: #d90b2c;
$accent_red_10: lighten($accent_red, 10%);
$accent_green: #299f9e;
$accent_green_10: rgba(41, 159, 158, 0.1);
$accent_orange: #f07922;
$accent_orange_10: lighten($accent_orange, 10%);
$light_grey: #00000099;
$dark_accent: #1a1a1a;
$faded_grey: #f4f7fa;
$deep_grey: #6b6b6b;
$mild-grey: #dadfe6;
$light_grey: #00000099;
$dark_accent: #1a1a1a;
$faded_grey: #f4f7fa;
$deep_grey: #6b6b6b;

$userBtnBg: #f7fafc;
$userBtnColor: #44576f;

$page_bg_primary: #f4f7fa;
$user_role_color: #2b63c1;
$form_input_color: #f7f7f7;

//media queries
$tiny: 300px;
$demi_tiny: 350px;
$semi_tiny: 400px;
$pre_small: 500px;
$small: 600px;
$semi_small: 700px;
$demi_small: 800px;
$medium: 900px;
$semi_medium: 1000px;
$large: 1200px;

// Fonts
$body_text: 'TTNormsProRegular', sans-serif;
$header_text: 'TTNormsProBold', sans-serif;

// Radius
$primary_radius: 5px;

// Sizes
$full_width: 1200px;
$half_width: 600px;
$quarter_width: 300px;
$mobile-only: 768px;
