@import '../../assets/styles/var';

.greenpole-alert {
  width: 350px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $white;
  font-size: 13px;
  font-weight: bold;
  padding: 0rem 1rem;

  button {
    background: transparent;
    border: 0px;
    cursor: pointer;

    &:focus {
      outline: none;
    }
    img {
      width: 10px;
      height: 10px;
    }
  }
}

.alert-success {
  background-color: $accent_green;
}

.alert-warning {
  background-color: $accent_orange;
}

.alert-error {
  background-color: $accent_red;
}
