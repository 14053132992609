@import '../../../../lib/assets/styles/var';

.activity-log-section {
  background-color: #ffffff;
  border-radius: 5px;
  padding-top: 25px;
  margin-top: 20px !important;

  .activity-log-lists {
    margin: 0 auto;
    width: 95%;
    padding-bottom: 43px;
  }

  .activity-item {
    padding-left: 1em;
    padding-right: 1em;

    table tr.ant-table-row {
      background-color: transparent;
      transition: 60ms ease-in;
      transition-duration: 2400ms;

      td {
        font-size: 16px;
        line-height: 30px;
        transition: all 0.8s ease-out;
        color: #666666;
        padding: 1rem 0rem;
      }

      &:hover {
        td {
          font-size: 15px;
          color: $primary_dark;
          translate: 50%;
          background-color: transparent;
          transition: all 0.3s ease-in;
        }
      }
    }
  }
}
