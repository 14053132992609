@import '../../assets/styles/var';

.avatar-xl {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid $primary_light;
}

.avatar-sm {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid $primary_light;
}
