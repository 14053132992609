@import '../../assets/styles/var';

.page-title {
  margin-bottom: 2rem;

  .breadcrumb {
    font-family: $body_text;
    font-size: 16px;
    font-weight: normal;
    opacity: 0.6;
    color: #00000099;
    font-size: 14px;
    margin-bottom: 5px;

    a {
      text-decoration: none;

      &:hover {
        color: $primary;
      }
    }

    span {
      text-transform: capitalize;
    }
  }

  .seperator {
    margin: 0px 5px;
  }

  .title {
    font-size: 30px;
    font-family: $header_text;
    text-transform: capitalize;
    font-weight: 600;
    color: #000000;
  }

  .desc {
    margin-top: 20px;
    color: $grey;
  }
}
