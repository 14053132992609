@import '../../lib/assets/styles/var';
@import 'react-big-calendar/lib/sass/styles';

.dashboard-module {
  height: 100%;
  overflow-y: auto;

  .section-header {
    color: $header_text_color;
    font-family: $header_text;
    font-size: 20px;
    line-height: 30px;
    text-transform: capitalize;
    padding-left: 35px;
  }

  .dashboard-col-2 {
    position: fixed;
    top: 4.5rem;
    right: 0;
    z-index: 1;
    width: 30%;
    height: 91vh;
    overflow-y: auto;

    .dashboard-tabs {
      .custom-calender {
        width: 98.5%;
        height: 50vh;
        border-radius: 0px;
        background-color: #fdfdfd;
      }

      .set-reminder-container {
        background-color: #ffffff !important;
        width: 98.5%;
        padding: 1em 1em;
        border-bottom: 2px solid #f8f9fa;

        .set-reminder-button {
          background-color: #299f9e;
          color: #ffffff;
          border-radius: 5px;
        }
      }
      .ant-tabs-nav-list {
        width: 98.5%;
      }

      .ant-tabs {
        &-nav {
          display: flex;

          .ant-tabs-tab {
            flex-grow: 1;
            margin-right: 0px;
            text-align: center !important;
          }
        }
      }
    }

    .tabs-title {
      font-size: 20px;
      line-height: 30px;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      min-width: calc(100% / 3);
      color: $grey;
      text-align: center;
      text-transform: capitalize;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      .tabs-title {
        color: $primary;
        font-family: $header_text;
        text-align: center;
      }
    }
  }
}

.reminder-form-modal {
  .ant-modal-header {
    background-color: #299f9e !important;
  }

  .ant-modal-title {
    text-align: center;
    color: #ffffff;
  }

  .ant-modal-close-x {
    color: #ffffff;
    display: block !important;
  }

  .ant-modal-body {
    padding: 0px;
  }

  .reminder-calender {
    width: 100%;
    height: 10em !important;
    border-radius: 0px;
    background-color: #fdfdfd;
    border: 1px solid red;
  }

  .form-container {
    // background-color: #F5F7F9;
    padding: 1em 3em;

    .ant-picker {
      background-color: #f7f7f7;
      border: none;
    }

    .ant-input {
      background-color: #f7f7f7;
      border: none;
    }

    .save-button {
      background-color: #299f9e;
      color: #ffffff;
      border-radius: 3px;
      width: 35%;
      border: none;
      font-weight: 700;
    }

    .cancel-button {
      background-color: #e6eaef;
      color: #299f9e;
      border-radius: 3px;
      width: 35%;
      border: none;
      font-weight: 700;
    }
  }
}
