@import '../../assets/styles/var';

.greenpole-default-card {
  background-color: $white;
  border: 1px solid $primary-light;
  padding: 1.25rem 1rem;
  color: $black;
  border-radius: 3px;
  -webkit-box-shadow: -1px 1px 5px -1px rgba(102, 102, 102, 0.25);
  -moz-box-shadow: -1px 1px 5px -1px rgba(102, 102, 102, 0.25);
  box-shadow: -1px 1px 5px -1px rgba(102, 102, 102, 0.25);

  &:hover {
    border: 1px solid $primary-dark;
    -webkit-box-shadow: -1px 10px 15px 3px rgba(238, 238, 238, 1);
    -moz-box-shadow: -1px 10px 15px 3px rgba(238, 238, 238, 1);
    box-shadow: -1px 10px 15px 3px rgba(238, 238, 238, 1);
  }
}

.greenpole-activity-card {
  background-color: $white;
  -webkit-box-shadow: -1px 1px 5px -1px rgba(102, 102, 102, 0.25);
  -moz-box-shadow: -1px 1px 5px -1px rgba(102, 102, 102, 0.25);
  box-shadow: -1px 1px 5px -1px rgba(102, 102, 102, 0.25);

  .header {
    height: 4rem;
    font-weight: bold;
    border-bottom: 1px solid lighten($grey, 50%);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  .body {
    padding: 1rem;
  }
}

.greenpole-notification-card {
  height: 5rem;
  border: 1px solid lighten($grey, 40%);
  padding: 1.5rem 1rem 0rem;
  border-radius: 3px;

  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    .subject {
      font-size: 14px;
    }

    .time {
      font-size: 12px;
      color: $grey;
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    margin-top: 0.5rem;

    .from {
      color: $grey;
    }

    .priority {
      span {
        padding: 5px 10px;
        text-transform: capitalize;
        border-radius: 50px;
        margin-left: 1rem;

        &.high {
          background-color: lighten($accent_red, 40%);
          border: 1px solid $accent_red;
          color: $accent_red;
        }

        &.medium {
          background-color: lighten($accent_orange, 40%);
          border: 1px solid $accent_orange;
          color: $accent_orange;
        }

        &.low {
          background-color: lighten($accent_green, 40%);
          border: 1px solid $accent_green;
          color: $accent_green;
        }
      }
    }
  }
}
