@import '../../assets/styles/var';

input[type='text'],
.custmized_input {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: none;
  display: block;
  position: relative;
  height: 50px;
  opacity: 1;
  width: 100%;
  z-index: 0;

  &:focus {
    outline: none;
    border: none;
  }
  &::placeholder {
    color: $grey;
    opacity: 0.4;
  }
  &:disabled {
    background: $grey;
    border: none;
    color: $white;
  }

  .row {
    width: 100vw;
    display: block;
  }

  .form-control {
    width: 100%;
    height: 50px;
    margin: 5px 0;
    border: none;
    display: block;
    border-radius: $primary_radius;
    opacity: 1;
    text-indent: 17px;
  }

  .greenpole_select {
    margin: 10px 0;
    opacity: 1;
    display: block;
    font-family: $body_text;
    line-height: 1.3;
    padding: 0.6em 1.4em 0.5em 0.8em;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 0.5em;
    -webkit-appearance: none;
    border-radius: $primary_radius;
    appearance: none;
    background-color: #f7f7f7;
  }

  .greenpole_select::-ms-expand {
    display: none;
  }

  .greenpole_select:hover {
    border-color: #888;
  }

  .greenpole_select:focus {
    border: none;
    outline: none;
  }

  .input-wrapper-block {
    display: block;
    position: relative;
    margin: 50px;
    width: $full_width;
  }

  .label {
    text-align: left;
    font-family: $body_text;
    font-size: 16px;
    letter-spacing: 0px;
    color: $grey;
    opacity: 1;
  }
  .input-wrapper-600 {
    display: inline-block;
    position: relative;
    margin: 20px 6px;
    width: 50%;
  }

  .input-wrapper-300 {
    display: inline-block;
    position: relative;
    margin: 20px 6px;
    width: 48%;
  }
  .input-wrapper-150 {
    display: inline-block;
    position: relative;
    margin: 20px 4px;
    width: 32%;
  }

  .greenpole_textarea {
    width: 600px;
    min-height: 50px;
    height: 80px;
    display: block;
    border: none;
  }
  .inputError {
    font-weight: lighter;
    font-family: $body_text;
    text-align: left;
    // position: absolute;
    width: 100%;
    bottom: -19px;
    left: 0;
    color: $accent_red;
  }

  .checkbox-label input {
    // position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkbox-label .checkbox-custom {
    // position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: transparent;
    border-radius: $primary_radius;
    border: 2p solid $white;
  }
  .ml-40 {
    margin-left: 30px;
  }
  .pr-10 {
    padding-right: 10px;
  }

  .checkbox-label input:checked ~ .checkbox-custom {
    background-color: $primary;
    border-radius: $primary_radius;
    -webkit-transform: rotate(0deg) scale(1);
    -ms-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity: 1;
    border: 2px solid $primary;
  }

  .checkbox-label .checkbox-custom::after {
    // position: absolute;
    content: '';
    left: 12px;
    top: 12px;
    height: 0px;
    width: 0px;
    border-radius: $primary_radius;
    border: solid $primary;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(0deg) scale(0);
    -ms-transform: rotate(0deg) scale(0);
    transform: rotate(0deg) scale(0);
    opacity: 1;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
  }

  .checkbox-label input:checked ~ .checkbox-custom::after {
    -webkit-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    opacity: 1;
    left: 8px;
    top: 3px;
    width: 6px;
    height: 12px;
    border: solid $white;
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
  }

  .checkbox-label input:checked ~ .checkbox-custom::before {
    left: -3px;
    top: -3px;
    width: 24px;
    height: 24px;
    border-radius: $primary_radius;
    -webkit-transform: scale(3);
    -ms-transform: scale(3);
    transform: scale(3);
    opacity: 0;
    z-index: 999;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
  }

  .checkbox-label .checkbox-custom.circular {
    border-radius: 50%;
    border: 2px solid $white;
  }

  .checkbox-label input:checked ~ .checkbox-custom.circular {
    background-color: $primary;
    border-radius: 50%;
    border: 2px solid $white;
  }
  .checkbox-label input:checked ~ .checkbox-custom.circular::after {
    border: solid $white;
    border-width: 0 2px 2px 0;
  }
  .checkbox-label .checkbox-custom.circular::after {
    border-radius: 50%;
  }

  .checkbox-label .checkbox-custom.circular::before {
    border-radius: 50%;
    border: 2px solid $white;
  }

  .checkbox-label input:checked ~ .checkbox-custom.circular::before {
    border-radius: 50%;
  }

  input[type='radio'] {
    background-color: initial;
    cursor: default;
    appearance: radio;
    box-sizing: border-box;
    margin: 3px 3px 0px 5px;
    padding: initial;
    border: initial;
    width: 24px;
    height: 24px;
    // position: absolute;
  }

  input[type='checkbox'] {
    background-color: initial;
    cursor: default;
    appearance: checkbox;
    box-sizing: border-box;
    margin: 3px 3px 0px 5px;
    padding: initial;
    border: initial;
    width: 24px;
    height: 24px;
    // position: absolute;
  }
}
