@import '../../assets/styles/var';

.form-field {
  margin-bottom: 25px;

  .react-select-container {
    width: 100%;
    height: 40px;
  }

  & .react-select__control {
    background-color: $field_bg !important;
    border-color: transparent !important;
    // box-shadow: 0 0 0 1px $primary !important;

    &:hover {
      border-color: $primary !important;
    }
  }

  & .react-select__indicator-separator {
    display: none;
  }

  & .react-select__value-container {
    height: 40px;
  }

  & .react-select__dropdown-indicator {
    color: $black !important;
  }

  & .required {
    &::after {
      content: '*';
      color: $accent_red;
    }
  }

  & em {
    color: $accent_red;
    font-style: normal;
    font-size: 20px;
  }

  & label {
    margin-bottom: 8px;
    display: block;
    width: 100%;
    color: $black;
    font-size: 20px;
    position: relative;
  }

  & .error {
    border: 1px solid $accent_red;
  }
}
