.confirmation-modal-cancel-btn {
  background-color: #fff;
  color: #da1232;
  border-radius: 3px;
  border: 1px solid #da1232;
  font-weight: 700;
  height: 50px;
  width: 100%;
}

.confirmation_result {
  & > .ant-result-extra {
    & > .ant-row.confirmation-row-buttons {
      display: flex !important;
      justify-content: center;
    }
  }
}

.confirmation-modal-proceed-btn {
  background-color: #299f9e !important;
  color: #ffffff !important;
  border-radius: 3px;
  border: none;
  font-weight: 700;
  height: 50px;
  width: 100%;
  margin-bottom: 1rem;
}

.ant-btn {
  display: block !important;
}
