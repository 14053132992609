@import '../../../../lib/assets/styles/var';

.notification-chart-section {
  background-color: $white;
  border-radius: 5px;
  padding-top: 25px;
  margin-top: 20px !important;

  .notification-empty-container {
    margin-bottom: 2em;
  }

  .notification-list {
    padding-top: 3px;
    padding-left: 24px;
    padding-right: 24px;

    ul {
      text-align: left;
      list-style: none;
      // width: min-content;
      // margin: 0 auto;
      padding-left: 10px;
      line-height: 30px;
    }

    li {
      color: $body_text_color;
      font-family: $body_text;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0.09px;
      margin: 8px 0;
      cursor: pointer;

      &::before {
        color: $grey;
        vertical-align: -60%;
        content: '\00b7';
        font-size: 100px;
      }
    }
  }
}

.last-notification-table {
  width: 100%;
  margin-top: 1em;
  font-size: 1em;
}

.last-notification-table th,
td {
  text-align: left;
  padding: 0.5em 0.5em 0.5em 2em;
}

.notification-approved {
  border-left: 8px solid #299f9e;
}

.notification-rejected {
  border-left: 8px solid #d80b2c;
}

.notification-pending {
  border-left: 8px solid #da7e26;
}

.last-notification-table tr:nth-child(odd) {
  background-color: #f3f6f9;
}

.notification-button-approved {
  // border: 1px solid #299f9e;
  padding: 0.3em 1.5em;
  border-radius: 10px;
  color: #2c8181;
  background-color: #d6f6f6;
}

.notification-button-rejected {
  // border: 1px solid #d80b2c;
  padding: 0.3em 1.5em;
  border-radius: 10px;
  color: #d90b2c;
  background-color: #d90b2c1a;
}

.notification-button-pending {
  // border: 1px solid #DA7E26;
  padding: 0.3em 1.5em;
  border-radius: 10px;
  color: #da7e26;
  background-color: #fff6ed;
}
