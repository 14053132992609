@import '../../assets/styles/var';

.notification__filters {
  display: flex;
  background: $white;
  padding: 1rem;

  &-search {
    border: 1px solid $mild-grey;
    border-radius: 5px;
    width: 500px;
    // height: 3rem;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    padding: 0 4px;
    transition: all 0.2s ease-in;
    display: flex;
    justify-content: flex-end;

    &:hover,
    :active {
      border: 1px solid $accent_green;
    }

    > div:first-child {
      padding: 0 16px;
      border-right: 1px solid $mild-grey;
    }

    > input {
      width: 90%;
      background-color: $white;
      padding: 8px;

      &:hover,
      :active {
        border: 0px;
        outline: none;
      }
    }

    &--circle {
      width: 50px;
      height: 50px;
      background: #fafbfc;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border: 0px;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }
  }
}

a {
  text-decoration: none;
  color: $primary;
}

.ant-dropdown-filter {
  box-shadow: 0px 3px 36px #3e64922e;
  background: #ffffff 0% 0% no-repeat padding-box;
  color: red;
}
