@import '../../assets/styles/var';
@import '../../assets/styles/mixins';

.page-layout {
  min-height: 110vh;
  background: #f2f6fa url('../../assets/images/circular_pattern.svg') no-repeat
    bottom left;

  .mobile_sidebar_toggle {
    position: fixed;
    top: 80px;
    padding: 0 10px;
    transition: 250ms ease;
    background-color: $white;
    color: $accent_green;
    cursor: pointer;
    z-index: 199;
  }

  .main {
    // padding: 8rem 2rem 5rem;

    @include small-screens {
      padding: 7rem 0;
    }
  }

  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid #299f9e !important;
  }

  .ant-menu-item-selected {
    color: #299f9e !important;
  }

  .ant-menu-item:hover {
    color: #299f9e !important;
  }

  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover {
    color: #299f9e !important;
  }
}
