.ant-select-selector,
/* #control-hooks_shareholderType; */
/* .ant-select-selector select,  */
.customized_input .ant-input, 
.customized_input, 
.customized_input > input {
  height: 40px !important;
  /* background: #D0D7EF; */
  background: #f7f7f7 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  border: 0;
  opacity: 1;
}

.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  border: none !important;
}

.react-tel-input .form-control {
  // position: relative;
  //   font-size: 14px;
  //   letter-spacing: .01rem;
  //   margin-top: 0 !important;
  //   margin-bottom: 0 !important;
  //   padding-left: 48px;
  //   margin-left: 0;
  //   background: #FFFFFF;
  //   border: 1px solid #CACACA;
  //   border-radius: 5px;
  //   line-height: 25px;
  height: 40px;
  width: 100%;
  outline: none;
  background: #f7f7f7;
}

.label_font {
  font-family: 'TT Norms Pro';
  font-weight: normal;
  font-size: 16px;
}

.ant-input-group-addon {
  border: 0;
}
.ant-select-selection-item,
.ant-select-selection-placeholder {
  vertical-align: middle;
  line-height: 40px !important;
}

.browse {
  color: #299f9e;
  text-decoration: underline;
}

.ant-upload {
  height: 8rem !important;
}

.ant-select-selector {
  background: #f4f7fa !important;
}

.ant-picker-input > input[disabled] {
  background: transparent;
  color: black;
}

.ant-input.customized_input:disabled {
  background-color: #828282 !important;
  color: #fff !important;
}

.ant-select-disabled {
  background-color: #828282 !important;

  & .ant-select-selection-item {
    color: #fff !important;
  }
}

.ant-select-multiple .ant-select-selection-item {
  height: 32px !important;
}

.ant-select-multiple .ant-select-selector {
  min-height: 3em !important;
  height: auto !important;
}

// .ant-select:not(.ant-select-customize-input) .ant-select-selector {
//   background: #f7f7f7 !important;
// }
