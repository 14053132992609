@import '../../assets/styles/var';

.greenpole-pagination {
  display: flex;
  align-items: center;

  .counter {
    color: #718196;
    margin-right: 5px;
    margin-top: -1rem;
  }

  ul li {
    display: inline;
    margin-left: 5px;
    margin-right: 5px;

    button {
      width: 30px;
      height: 30px;
      background-color: #f7fafc;
      border: 1px solid #ccd5e0;
      color: #ccd5e0;
      border-radius: 4px;
      cursor: pointer;

      &.active,
      &:hover {
        background-color: #299f9e;
        color: $white;
      }

      &:disabled {
        opacity: 0.5;

        &:hover {
          background-color: #f7fafc;
          cursor: auto;
        }
      }
    }
  }
}
