@import '../../../../lib/assets/styles/var';

.recent-notifications {
  padding: 10px 25px;
  margin-bottom: 10px;
  background-color: $white;

  .notification-banner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 114px;
    // margin-bottom: 2em;
    border-radius: 10px;

    .notif-image {
      min-height: 100%;
      min-width: 100%;
      width: 100%;
      height: auto;
      position: absolute;
      top: -12px;
      left: 0;
    }
  }

  .notif-top {
    display: flex;
    justify-content: space-between;
    // padding-top: 2em;
    padding-bottom: 0.5em;

    .title {
      display: inline;
      letter-spacing: 0px;
      color: $body_text_color;
      font-family: $body_text;
      font-size: 14px;
      line-height: 22px;
      text-transform: capitalize;
    }

    .view-all-link {
      letter-spacing: 0px;
      color: $header_text_color;
      font-family: $header_text;
      font-size: 14px;
      line-height: 22px;

      a {
        color: $header_text_color;
        text-decoration: underline;
      }
    }
  }
}
