.loaderWrapper {
  &Fullscreen {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
  }

  &Mini {
    width: 100%;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
  }
}

.loaderWrapper .fullscreen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.loaderWrapper .mini {
  width: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.center_loader {
  margin: auto;
  width: 0;
  margin-top: 25%;
}
