@import '../../assets/styles/var';

.GP-default-table {
  border: none;
  max-width: 100%;
  overflow-x: auto;
  padding: 20px;
  width: 100%;
  text-align: left;

  @media only screen and (max-width: $mobile-only) {
    padding: 20px 5px;
  }

  td,
  th {
    padding: 15px;

    @media only screen and (max-width: $mobile-only) {
      padding: 5px;
    }
  }

  tr {
    &:last-child {
      td {
        border-bottom: none;
      }
    }
    &:nth-child(even) {
      background-color: transparentize($grey, 0.9);
    }
  }

  th {
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    text-transform: capitalize;
    background-color: transparentize($grey, 0.9);
  }

  td {
    border: none;
    color: $black;
    font-size: 14px;
  }
}

.tableGrid {
  border-collapse: collapse;
  td,
  th {
    padding: 10px;

    @media only screen and (max-width: $mobile-only) {
      padding: 5px;
    }
  }
  table,
  th,
  td:not(:last-child) {
    border: 1px solid transparentize($grey, 0.5);
    border-collapse: collapse;
  }
}

.tableHead {
  background-color: transparentize($grey, 0.9);

  > th {
    color: #666666;
  }
}

.tableBody {
  > tr {
    &:nth-child(even) {
      background-color: $white;
    }
  }
}

.ant-table .ant-table-row:nth-child(odd) {
  background-color: #fafafa !important;
}

table {
  // @media print {
  //   @page { size: landscape; }
  // }
  @media all {
    .page-break {
      display: none;
    }
  }

  @media print {
    html,
    body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }

  @page {
    size: auto;
    margin: 20mm;
  }
}
