@import '../../../../lib/assets/styles/var';

.notification-chart-section {
  background-color: $white;
  border-radius: 5px;
  padding-top: 25px;
  margin-top: 20px !important;

  .ant-spin-dot-item {
    background-color: $accent_green;
  }

  .notification-list {
    padding-top: 3px;
    padding-left: 24px;
    padding-right: 24px;

    ul {
      text-align: left;
      list-style: none;
      // width: min-content;
      // margin: 0 auto;
      padding-left: 10px;
      line-height: 30px;
    }

    li {
      color: $body_text_color;
      font-family: $body_text;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0.09px;
      margin: 8px 0;
      cursor: pointer;

      &::before {
        color: $grey;
        vertical-align: -60%;
        content: '\00b7';
        font-size: 100px;
      }
    }
  }
}
