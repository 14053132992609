@import '../../assets/styles/var';

.search-modal-container {
  .ant-modal-header {
    background-color: #299f9e !important;
  }

  .ant-modal-title {
    text-align: center;
    color: #ffffff;
  }

  .ant-modal-close-x {
    color: #ffffff;
  }
}

.search-form {
  background-color: #f4f7fa;
  border: 1px solid #d0d7ef;
  // width: 350px;
  // height: 0px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    height: 30px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    width: 100%;
    background: transparent;
    border: 0px;
    padding-left: 10px;

    &::placeholder {
      font-size: 12px;
      color: #6f7689;
    }

    &:focus {
      outline: none;

      + button {
        background-color: $accent_green;
      }
    }
  }

  button {
    height: 30px;
    width: 15%;
    background-color: #687da0;
    border: 0px;
    border-radius: 8px;

    img {
      filter: brightness(10);
      width: 20px;
      height: 20px;
    }
  }
}

.module-search {
  .form-control {
    width: auto;
    height: 60px;
    border: 1px solid #dadfe6;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 1rem;
    justify-content: space-between;

    .dropdown {
      width: 35%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 10px;
      font-size: 16px;
      color: #6f83a4;
      border-right: 1px solid #cccccc;
      cursor: pointer;
      box-shadow: none;
    }

    .input {
      display: flex;
      align-items: center;
      padding-left: 1rem;

      input {
        width: 90%;
        background: transparent;

        &::placeholder {
          font-size: 16px;
        }
      }
    }
  }

  .dropdown-content {
    padding: 1rem;
    background-color: $white;
    box-shadow: 0px 18px 48px #3e649214;
    border: 1px solid #1166f51a;
    position: relative;
    top: -5px;
  }
}
