@import '../../assets/styles/var';

.form-field {
  margin-bottom: 25px;

  & .required {
    &::after {
      content: '*';
      color: $accent_red;
    }
  }

  & em {
    color: $accent_red;
    font-style: normal;
    font-size: 14px;
  }

  & label {
    margin-bottom: 4px;
    display: block;
    width: 100%;
    color: $black;
    font-size: 20px;
    position: relative;
  }

  & .input {
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    font-size: 18px;
    border: 1px solid transparent;
    background-color: $field_bg;
    border-radius: 5px;
    transition: all 0.3s;
    color: $grey;
    display: block;
    width: 100%;
    outline: none;

    &:hover {
      border: 1px solid $grey;
    }

    &:focus {
      border: 1px solid $primary;
    }
  }

  & .error {
    border: 1px solid $accent_red;
  }
}
