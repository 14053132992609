@import '../../../../lib/assets/styles/var';

.activity-calendar {
  .calendar-title {
    color: $header_text_color;
    font-family: $header_text;
    font-size: 27px;
    line-height: 20px;
    padding-left: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .green-pole-calendar {
    padding-right: 1.2em;
    padding-left: 1.2em;
    margin-top: -1.5em;

    .rbc-time-view {
      border: none;
    }

    .rbc-day-slot .rbc-time-slot {
      border-top: none;
    }

    .rbc-timeslot-group {
      min-height: 70px;
    }

    .rbc-time-header {
      visibility: hidden;
    }

    .rbc-event-label {
      font-size: 14px;
      font-weight: bold;
      // color: #309191;
    }
    .rbc-event-content {
      font-size: 14px;
      font-weight: bold;
      // color: #309191;
    }
  }

  .calendar-day-event {
    opacity: 0.8;
    border: none !important;
    padding-left: 2em;
    display: block;
    border-radius: 0;
    padding-top: 6em;
    border-left: 3px solid $black !important;
    height: auto !important;
  }

  .calendar-day-view {
    background-color: transparent;
  }
}
